import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Typography from '@material-ui/core/Typography'
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

const styles = theme => ({

    paper: {
        padding: theme.spacing.unit * 2,
        marginBottom: 30,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    papertitle: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        marginBottom:5,
        color: theme.palette.text.secondary,
    },
    typography: {
        useNextVariants: true,
    },
    button: {
        margin: theme.spacing.unit,
    },
});



class PrivacyPolicy extends Component {
    render() {
        return (
            <Paper className={this.props.classes.paper}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <h3>Overview</h3>
                        <Typography  paragraph>This Privacy Policy describes how your personal information is handled in Elementals Music App for iOS.</Typography>
                        <h3>We collect No Personal Information in Elementals App</h3>
                        <Typography  paragraph>We do not collect, use, save, or have access to any of your personal data recorded in Elementals App.</Typography>
                        <Typography  paragraph>Individual audio recordings are not personal and are stored only on your device during the time of use.  We do not have access to that data and it is not shared with anyone unless you share it.</Typography>
                        <h3>Our Applications Comply with the Children’s Online Privacy Protection Act</h3>
                        <Typography>Our applications don’t collect personal information from anyone, including children under the age of 13.</Typography>
                    </GridItem>
                </GridContainer>
            </Paper>

        );
    }
}


PrivacyPolicy.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(PrivacyPolicy);
