import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "./../../../components/Grid/GridContainer.jsx";
import GridItem from "./../../../components/Grid/GridItem.jsx";
import InfoArea from "./../../../components/InfoArea/InfoArea.jsx";

import productStyle from "./../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import Mic from '@material-ui/icons/Mic';
import Music from '@material-ui/icons/LibraryMusic';
import Menu from '@material-ui/icons/QueueMusic';
import Person from '@material-ui/icons/Person';
import Shake from '@material-ui/icons/Gesture';
import Volume from '@material-ui/icons/VolumeUp';
import Delay from '@material-ui/icons/Waves';

class ProductSection extends React.Component {



  state = {
    isLoaded: false
  };



  render() {
    const { classes } = this.props;


    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Elementals is a custom music creation application for the iPhone and iPad.</h2>
            <h5 className={classes.description}>
              The concept of Elementals is to provide a simple user interface to create music by placing and swapping icons,called Elementals, on sections of the device screen, called Islands.
            </h5>
            <h5 className={classes.description}>
            The audio generated is a combination of the Elemental providing the audio sample and the Island shaping how the audio is played. You can change the music by moving the Elementals, swapping Elementals, gently shaking the device, and recording your own audio to a Elemental.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} ><InfoArea
                title="Customize"
                description="Customize your own music using unique samples"
                icon={Music}
                iconColor="success"
                vertical
            /></GridItem>
            <GridItem xs={12} sm={12} md={4} ><InfoArea
                title="Live Swapping"
                description="Choose an Elementals audio while playing music"
                icon={Menu}
                iconColor="success"
                vertical
            /></GridItem>
            <GridItem xs={12} sm={12} md={4} ><InfoArea
                title="Cosmic to Tribal"
                description="Simple interaction produces interesting music"
                icon={Person}
                iconColor="warning"
                vertical
            /></GridItem>
            <GridItem xs={12} sm={12} md={4} ><InfoArea
                title="Simple Gestures - Big Effects"
                description="Utilize shake gesture to easily mix or start music"
                icon={Shake}
                iconColor="rose"
                vertical
            /></GridItem>
            <GridItem xs={12} sm={12} md={4} ><InfoArea
                title="Mixing"
                description="Adjust volume per island/sample"
                icon={Volume}
                iconColor="success"
                vertical
            /></GridItem>
            <GridItem xs={12} sm={12} md={4} ><InfoArea
                title="Recording"
                description="Record your own audio tracks, up to 7 tracks available"
                icon={Mic}
                iconColor="primary"
                vertical
            /></GridItem>
            <GridItem xs={12} sm={12} md={4} ><InfoArea
                title="Built in Effects"
                description="Add delay to recorded audio"
                icon={Delay}
                iconColor="warning"
                vertical
            /></GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }

}

export default withStyles(productStyle)(ProductSection);
