import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Typography from '@material-ui/core/Typography'
import Tip from '@material-ui/icons/Info';
import InfoArea from "../../components/InfoArea/InfoArea";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

const styles = theme => ({

    paper: {
        padding: theme.spacing.unit * 2,
        marginBottom: 30,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    papertitle: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        marginBottom:5,
        color: theme.palette.text.secondary,
    },
    typography: {
        useNextVariants: true,
    },
    button: {
        margin: theme.spacing.unit,
    },
});



class FAQ extends Component {
    render() {
        return (
            <Paper className={this.props.classes.paper}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <h3>How To Play</h3>
                        <Typography  paragraph>To quickly start playing, gently shake the device to move Elementals on to Islands randomly. You can touch an Elemental on an island or sitting idle to move it to an Island, either occupied or vacant.</Typography>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} >
                        <InfoArea
                            title=""
                            description="When adding an Elemental or swapping one,the change may begin immediately or gradually. The application will find the best place to make a smooth switch when swapping Elementals."
                            icon={Tip}
                            iconColor="success"
                            horizontal
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                        <InfoArea
                            title=""
                            description="Certain Islands, usually two per a board will add delay effect to the audio of an Elemental. Read about recording audio and add your recording to these islands and you can produce some interesting music and sounds."
                            icon={Tip}
                            iconColor="success"
                            horizontal
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <h3>Swapping Elementals</h3>
                        <Typography   paragraph>You can swap one Elemental for another on an island by moving the new elemental over the one to switch. This will cause the music to change and alter the audio of both Elementals. The two Elementals will switch places, which could be leaving the board and back home. This will cause that Elemental&#8217;s music to fade out and stop playing.</Typography>
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <h3>Elementals on iPod Touch</h3>
                        <Typography   paragraph>Elementals is currently being tested on iPod Touch for a future release.</Typography>
                        <h3>Volume Adjustments</h3>
                        <Typography   paragraph>To adjust the volume for a specific playing Elemental, you simply tap the Elemental. One tap will raise the volume, and two taps will lower the volume.</Typography>
                        <h3>Stop an Elemental Playing</h3>
                        <Typography   paragraph>To stop an Elemental playing, move it off an island by touching and dragging it to an open area on the screen.</Typography>
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <h3>Recording Audio</h3>
                        <Typography   paragraph>If a microphone is available for audio recording, there will be a red colored Island in the center of the screen. Move an Elemental on a record-enabled island and tap the Elemental to start recording. The Elemental will start to spin, which means it is recording. To stop recording, tap the spinning Elemental or move it to another Island.</Typography>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} >
                        <InfoArea
                            title=""
                            description="You are only limited by the number of islands on a board for personal audio recordings using a head set microphone."
                            icon={Tip}
                            iconColor="warning"
                            horizontal
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                        <InfoArea
                            title=""
                            description="You can drag an recording Elemental off the island to open space and it will stop recording which discards any audio recording."
                            icon={Tip}
                            iconColor="success"
                            horizontal
                        />
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <h3>Configuration Options</h3>
                        <Typography   paragraph>The application allows you to configure the game board and what sounds an Elemental plays when on an Island. You can change the game board which will result in a new music theme and the type of music being generated in the Board Selection section of Configuration options.</Typography>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} >
                        <InfoArea
                            title=""
                            description="You can override the audio being played on a game board in the Elementals Configuration option. When you override an Elemental&#8217;s sounds, it will play the samples you pick no matter what the game board selected."
                            icon={Tip}
                            iconColor="info"
                            horizontal
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                        <InfoArea
                            title=""
                            description="While playing you can change and alter an Elemental&#8217;s custom samples. The sample will be used on the next change, such as swapping or being added to an Island."
                            icon={Tip}
                            iconColor="success"
                            horizontal
                        />
                    </GridItem>
                </GridContainer>


            </Paper>

        );
    }
}


FAQ.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(FAQ);
