import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "./../../components/Header/Header.jsx";
import Footer from "./../../components/Footer/Footer.jsx";
import GridContainer from "./../../components/Grid/GridContainer.jsx";
import GridItem from "./../../components/Grid/GridItem.jsx";
import HeaderLinks from "./../../components/Header/HeaderLinks.jsx";
import Parallax from "./../../components/Parallax/Parallax.jsx";
import Faq from './FAQDetails'
import profile from "./../../assets/img/Icon120.jpg";

import profilePageStyle from "./../../assets/jss/material-kit-react/views/profilePage.jsx";
import Background from "../../assets/img/WaterBoardBackGround.jpg";
class FAQ extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    //const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <Header
          color="transparent"
          brand="Elementals iOS App"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={Background} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <div>
                      <img src={profile} alt="..." className={imageClasses} />
                    </div>
                    <div className={classes.name}>
                      <h3 className={classes.title}>Elementals FAQ</h3>
                      <h4 className={classes.subtitle}>Help and Tips</h4>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6} lg={12}>
                        <Faq/>
                    </GridItem>
                </GridContainer>
              <div >
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(FAQ);
