/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import {Apps, Help, PhotoAlbum} from "@material-ui/icons";
// core components
import Button from "../../components/CustomButtons/Button.jsx";

import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
        <ListItem className={classes.listItem}>
            <Button
                href="/#/faq"
                color="transparent"
                className={classes.navLink}
            >
                <Apps className={classes.icons} /> FAQ
            </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Button
                href="/#/screenshots"
                color="transparent"
                className={classes.navLink}
            >
                <PhotoAlbum className={classes.icons} /> ScreenShots
            </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Button
                color="transparent"
                className={classes.navLink}
                id={"supportLink"}
            >
                <Help className={classes.icons} /> Support
            </Button>
        </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="itunesstore"
          title="Elementals in iTunes"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="http://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=315021672&mt=8"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-itunes"} />
          </Button>
        </Tooltip>
      </ListItem>

    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
