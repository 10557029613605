import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter, Route, Switch } from "react-router-dom";

import "./assets/scss/material-kit-react.scss?v=1.4.0";

// pages for this product
import LandingPage from "./views/LandingPage/LandingPage.jsx";
import ScreenShots from "./views/ScreenShots/ScreenShots.jsx";
import FAQ from "./views/FAQPage/FAQ";
import Video from './views/Video/Video'
import Privacy from './views/PrivacyPolicy/PrivacyPolicy'
var hist = createBrowserHistory();

ReactDOM.render(
  <HashRouter history={hist}>
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/screenshots" component={ScreenShots} />
      <Route path="/faq" component={FAQ} />
      <Route path="/video" component={Video} />
      <Route path="/privacy" component={Privacy} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
