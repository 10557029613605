import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Parallax from "../../components/Parallax/Parallax.jsx";

import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";
// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx";
import Button from "../../components/CustomButtons/Button";
import Background from "../../assets/img/WaterBoardBackGround.jpg";
// @material-ui/icons

const dashboardRoutes = [];

class LandingPage extends React.Component {


  render() {
    const { classes, ...rest } = this.props;


    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Elementals an iOS App"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={Background}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 >Elementals</h1>
                <h3 >An iPad and iPhone Universal App for making music using Elementals.</h3>
                  <h4>Interactive music boards with an ability to record your own samples.</h4>
                  <h4>One app with different boards for various devices.</h4>

                <br />
                <Button
                  color="success"
                  size="lg"
                  target="_blank"
                  href="http://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=315021672&mt=8"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-play" />
                  View in iTunes
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
              <ProductSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
