import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({

    paper: {
        padding: theme.spacing.unit * 2,
        margin:10,
        marginBottom: 30,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.secondary,
    },

    typography: {
        useNextVariants: true,
    }
});

const ipad_images = [{
    "src": "images/BlueMatrixPromoElementSelect.jpg",
    "thumbnail": "images/bluematrixpromoelementselect-160x120.jpg",
    "thumbnailWidth": 160,
    "thumbnailHeight": 120,
    "caption":"Water World"
},{
    "src": "images/FireIslandPromoShot.jpg",
    "thumbnail": "images/fireislandpromoshot-160x120.jpg",
    "thumbnailWidth": 160,
    "thumbnailHeight": 120,
    "caption":"Water World"
},{
    "src": "images/WaterPromo.png",
    "thumbnail": "images/waterpromo-160x120.png",
    "thumbnailWidth": 160,
    "thumbnailHeight": 120,
    "caption":"Water World"
}];

const iphone_images = [{
    "src": "images/IMG_0003.jpg",
    "thumbnail": "images/IMG_0003.jpg",
    "thumbnailWidth": 160,
    "thumbnailHeight": 240
},{
    "src": "images/IMG_0004.jpg",
    "thumbnail": "images/IMG_0004.jpg",
    "thumbnailWidth": 160,
    "thumbnailHeight": 240
},{
    "src": "images/IMG_0010.jpg",
    "thumbnail": "images/IMG_0010.jpg",
    "thumbnailWidth": 160,
    "thumbnailHeight": 240
},{
    "src": "images/IMG_0012.jpg",
    "thumbnail": "images/IMG_0012.jpg",
    "thumbnailWidth": 160,
    "thumbnailHeight": 240
},{
    "src": "images/IMG_0011.jpg",
    "thumbnail": "images/IMG_0011.jpg",
    "thumbnailWidth": 160,
    "thumbnailHeight": 240
}];


class IPad extends Component {

    render() {
        return (<div><Paper  className={this.props.classes.paper}>
                <Typography variant={"h5"} gutterBottom>iPad Screenshots</Typography>
                <Gallery images={ipad_images} />
            </Paper>
                <div>
            <Paper  className={this.props.classes.paper} >
                <Typography variant={"h5"} gutterBottom>iPhone Screenshots</Typography>
                <Gallery  images={iphone_images}/>
            </Paper></div></div>
        )};
}




IPad.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(IPad);